.Margin-div {
  margin: 0 20%;
  @media only screen and (max-width: 800px) {
    margin: 0 20px;
  }
  @media only screen and (max-width: 420px) {
    margin: 0 10px;
  }
}
.Display-inline {
  display: inline;
}
.Font-weight-700{
  font-weight: 700;
}
.Align-right {
  text-align: right;
}
.Align-center {
  text-align: center;
}
.White {
  color: #fff;
}
.margin-bottom-100{
  margin-bottom: 100px;
}
.margin-bottom-50{
  margin-bottom: 50px;
}

.Pop-in {
  animation: pop-in 600ms ease;
}
@keyframes pop-in {
  from{
    opacity: 0;
  }
  50% {
    font-size: 1.2rem;
  }
  to{
    opacity: 1;
    font-size: 1rem;
  }
}
a {
  color: #12d1b8;
  text-decoration: none;
}
.Contact-card-container{
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.Contact-card{
  padding: 20px 20px;
  @media only screen and(max-width: 600px){
    padding: 10px;
    margin: 20px 5px;
  }
  margin: 20px 10%;
  background-color: #333;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: #000 5px 5px 5px;
  text-align: center;
  // display: block;
}
.Fade-in {
  animation: Fade-in 1000ms ease;
}
@keyframes Fade-in {
  from{
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.Back-to-top{
  position: fixed;
  // z-index: -111;
  bottom: 10px;
  left: 10px;
  text-decoration: none;
  color: #12d1b8;
  border-radius: 5px;
  background-color: #000;
  padding: 2px;
  opacity: 0.6; 
}