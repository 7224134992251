.Profile-pic{
    // height: 300px;
    width: 100%;
    border-radius: 50%;
    margin: auto;
    display: block;
    @media only screen and (min-width: 1030px) {
        width: auto;
        height: 400px;
    }
    @media only screen and (max-width: 500px) {
        width: 50%;
    }
}
.About-me{
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    @media only screen and (max-width: 500px) {
        grid-template-columns: 100%;
    }
}