.Featured-img-container {
    // width: 100%;
    height: 400px;
    opacity: 1;
    background: url('../images/hawaii-sunset.jpg') no-repeat;
    z-index: 0;
    background-color: #dfdfdf;
    background-size: cover;
    background-position-y: 25%;
}
.Page-title-container {
    display: grid;
    grid-template-rows: 100%; 
    height: 400px;
}
.Page-title {
    color: #12d1b8;
    text-align: center;
    background-color: #0009;
    font-size: 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 0;
    margin: 0 0;
}

.Featured-img-img {
    width: 50%;
    // height: 500px;
}


// .Binary-animation-container {
//     position: fixed;
//     overflow: hidden;
//     width: 100%;
//     // top: 200px;
//     top: 115px;
//     box-sizing: content-box;
// }
// .Binary-animation{
//     color: green;
//     animation: Binary-animation 8000ms linear infinite;
//     opacity: 0;
//     font-size: 1.3rem;
//     @media only screen and (max-width: 800px){
//         font-size: 1rem;
//     }
//     @media only screen and (max-width: 640px){
//         font-size: 0.7rem;
//     }
//     @media only screen and (max-width: 450px){
//         font-size: 0.4rem;
//     }
// }

// @keyframes Binary-animation {
//     from {
//         transform: translate(-100%);
//         opacity: 0;
//     }
//     10% {
//         opacity: 1;
//     }
//     90% {
//         opacity: 1;
//     }
//     to {
//         opacity: 0;
//         transform: translate(100%);
//     }
// }
