.dots-container{
    display: flex;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
}
.solid-dot{
    border: 3px solid #000;
    background-color: #fff;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin: 0 1px;
    // float: right;
}
.empty-dot {
    border: 3px solid #000;
    border-radius: 50%;
    background-color: #000;
    width: 5px;
    height: 5px;
    margin: 0 1px;
}
.TestimonialSlider-container {
    overflow: hidden;
    box-sizing: content-box;
    display: block;
    // grid-template-columns: repeat(1, 99%);
    border: 1px solid #000;
    border-radius: 3px;
    box-shadow: #777 5px 5px 5px;
    // margin: 10px;
    padding: 5px 5px 20px 5px;
    background-color: #666;
}
.TestimonialSlider{
    
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    // background-color: #ffffcc;
    margin: 0 10px;
}


.Testimonial-slide-in {
    animation: testimonial-slide-in 500ms linear;

}
@keyframes testimonial-slide-in {
    from {
        transform: translate(50%);
    }
    to {
        transform: translate(0);
    }
}
.Testimonial-slide-in-c {
    animation: testimonial-slide-in-c 500ms linear;
}
@keyframes testimonial-slide-in-c {
    from {
        transform: translate(50%);
    }
    to {
        transform: translate(0);
    }
}
.See-more-btn {
    // border: 2px solid #fff;
    // border-radius: 50%;
    // background-color: #12d1b8;
    display: block;
    margin: 0 auto;
    // padding: 5px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    // color: #fff;
    // font-size: 1.2rem;
    // text-align: center;
    // float: right;
}
.left-testimonial-btn{
    float: left;
}
.right-testimonial-btn{
    float: right;
}