
.NavbarRouter {

}
.NavbarRouter-nav {
    display: grid;
    grid-template-columns: 33% 67%;
    @media only screen and (min-width: 1500px) {
        grid-template-columns: 11% 89%;
    }
    @media only screen and (min-width: 1100px) {
        grid-template-columns: 15% 85%;
    }
    @media only screen and (min-width: 750px) {
        grid-template-columns: 21% 79%;
    }
    @media only screen and (min-width: 420px) {
        grid-template-columns: 25% 75%;
    }
}
.NavbarRouter-nav-img {
    height: 100px;
}
.NavbarRouter-nav-items-container {
    animation: slide-in-from-bot 500ms linear;
    z-index: -100;
    // display: flex;
    background-color: #f8f8f8;
    border-top: 2px solid #000;
    text-align: center;

}
@keyframes slide-in-from-bot {
    from {
        transform: translate(0, 40px);
    }
    60% {
        transform: translate(0, -10px);

    }
    to {
    }
}
.NavbarRouter-nav-item{
    font-size: 1.2rem;
    padding: 10px;
    display: inline-block;
    text-decoration: none;
    color: #122bd8;
    // margin: auto;
}
.NavbarRouter-nav-button {
    float: right;
    border: 2px solid #000;
    border-radius: 5px;
    margin-top: 10px;
    height: 80px;
    width: 80px;
    background-color: #12d1b8;
    cursor: pointer;
    .NavbarRouter-nav-button-line{
        height: 20px;
        width: 70px;
        background-color: #fff;
        margin: 5px auto;
        border-radius: 7px;
        text-align: center;
    }
    .NavbarRouter-nav-button-close-line {
        height: 20px;
        width: 70px;
        background-color: #fff;
        border-radius: 7px;
        animation: NavbarRouter-nav-button-close-line-1 500ms linear;
        transform: rotate(-45deg);
        position: relative;
        top: 30px;
        left: 5px;

    }
    .NavbarRouter-nav-button-close-line-2 {
        height: 20px;
        width: 70px;
        background-color: #fff;
        border-radius: 7px;
        animation: NavbarRouter-nav-button-close-line-2 500ms linear;
        transform: rotate(45deg);
        position: relative;
        top: 10px;
        left: 5px;
    }
    .NavbarRouter-nav-button-close-line-3 {
        height: 20px;
        width: 70px;
        background-color: #fff;
        margin: 5px auto;
        border-radius: 7px;
        animation: NavbarRouter-nav-button-close-line-3 500ms linear;
        // display: block;
        position: relative;
        top: 10px;
        opacity: 0;
    }
}
@keyframes NavbarRouter-nav-button-close-line-1 {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(-45deg);
    }
}
@keyframes NavbarRouter-nav-button-close-line-2 {
    from{
        transform: rotate(0);
    }
    to {
        transform: rotate(45deg);
    }
}
@keyframes NavbarRouter-nav-button-close-line-3 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

